import classes from '../style';
import { openLookup } from '../openCloseSearch';

const plus = 'M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z';

const spin = 'm8 0c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8m4.88 9.21c-.57 2.23-2.58 3.79-4.88 3.79s-4.31-1.56-4.88-3.79c-.1-.38.13-.76.51-.86.06-.02.12-.02.18-.02.32 0 .6.22.68.53.41 1.61 1.86 2.73 3.52 2.73s3.1-1.12 3.52-2.73c.08-.31.36-.53.68-.53.06 0 .12.001.18.02.18.05.34.16.43.33s.12.35.07.54';

export const createFloatingButton = (document, buttonDiv, el, cb = () => { }, Api) => {
  if (el) {
    const [svgSpin] = document.getElementsByClassName('epospl-svg-spin');
    if (svgSpin) {
      svgSpin.remove();
    }
    buttonDiv.className = classes.floatingButton;
    const svgPlus = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgPlus.setAttribute('height', '24px');
    svgPlus.setAttribute('width', '24px');
    const svgPlusPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    svgPlusPath.setAttribute('fill', '#fff');
    svgPlusPath.setAttribute('d', plus);
    svgPlus.appendChild(svgPlusPath);
    buttonDiv.appendChild(svgPlus);
    buttonDiv.addEventListener('click', () => openLookup(el, cb, Api));
  } else {
    buttonDiv.className = ` ${classes.floatingButton} ${classes.spinningSvg}`;
    const svgSpin = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgSpin.setAttribute('height', '56px');
    svgSpin.setAttribute('class', 'epospl-svg-spin');
    svgSpin.setAttribute('width', '56px');
    svgSpin.setAttribute('viewBox', '0 0 16 16');
    const svgSpinPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    svgSpinPath.setAttribute('fill', '#fff');
    svgSpinPath.setAttribute('d', spin);
    svgSpin.appendChild(svgSpinPath);
    buttonDiv.appendChild(svgSpin);
  }

};
