// START HERE
import jss from 'jss';
import preset from 'jss-preset-default';

jss.setup(preset());

// Create your style.
const style = {
  htmlNoOverflow: {
    maxHeight: '100vh',
    overflow: 'hidden'
  },
  spinningSvg: {
    animation: 'spin .8s linear infinite'
  },
  searchContainer: {
    width: '200px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  searchIcon: {
    position: 'absolute',
    left: '5px'
  },
  searchClosed: {
    width: '60px',
    border: '1px solid #675f6b',
    cursor: 'pointer'
  },
  searchWrapper: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    order: 9,
    '&[class*=searchClosed] [class*=searchInput]': {
      display: 'none'
    },
    '&[class*=searchClosed] [class*=searchIcon]': {
      left: '16px',
      '& path': {
        fill: '#9a929e'
      }
    }
  },
  searchInput: {
    height: '40px',
    padding: '5px',
    paddingLeft: '35px',
    border: '1px solid #675f6b',
    '&:hover, &:focus': {
      outline: 0
    }
  },
  sizeName: {
    paddingLeft: '5px',
    margin: 0
  },
  sizePrice: {
    paddingRight: '5px',
    margin: 0
  },
  figureColourContainer: {
    extend: 'productContainer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    '&.figureColourContainerActive': {
      boxShadow: '3px 4px 4px #ccc',
      border: '5px solid red',
      '& > img': {
        transform: 'scale(1.3)'
      }
    }
  },
  colourImage: {
    extend: 'productImage'
  },
  colourName: {
    extend: 'productName',
    color: '#000',
    zIndex: 99
  },
  colourLinkContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  singleOptionColourContainer: {
    height: '150px',
    width: '150px',
    float: 'left'
  },
  tablesContainer: {
    width: '100%',
    padding: '10px',
    background: 'white',
    display: 'flex',
    position: 'absolute',
    top: 0,
    height: '80vh',
    flexWrap: 'wrap',
    overflowY: 'hidden'
  },
  sizesContainer: {
    height: '100%',
    width: '30%',
    background: 'white',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      /*display: 'none'*/
    }
  },
  coloursContainer: {
    height: '100%',
    width: '70%',
    background: 'white',
    display: 'block',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      /*display: 'none'*/
    }

    // flexWrap: 'wrap'
  },
  productActionOptionsContainer: {
    position: 'fixed',
    top: '100%',
    alignItems: 'center',
    backgroundColor: '#fefefe',
    justifyContent: 'space-around',
    overflow: 'hidden',
    display: 'flex',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  singleOptionSizeContainer: {
    width: '100%',
    minHeight: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0'
  },
  sizeOption: {
    position: 'relative',
    textDecoration: 'none',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    height: '45px',
    alignItems: 'center',
    color: '#333',
    border: '2px solid #333',
    fontWeight: 'bold',
    '&:after': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      margin: 0,
      width: '0%',
      content: "'.'",
      color: 'transparent',
      background: '#aaa',
      height: '1px',
      opacity: 0,
      textAlign: 'left'
    },
    '&:hover': {
      textDecoration: 'none',
      color: '#000',
      zIndex: 1,
      '&:after': {
        width: '100%',
        zIndex: -10,
        animation: 'fill 0s forwards',
        opacity: 1
      }
    }
  },
  productName: {
    margin: '0 2px',
    fontWeight: 700,
    textAlign: 'center',
    zIndex: 99
  },
  productImage: {
    //borderRadius: '50%'
  },
  radioGroup: {
    border: '1px solid #675f6b',
    display: 'inline-block',
    margin: '2px',
    borderRadius: '0px',
    overflow: 'hidden'
  },
  filterLabel: {
    color: '#9a929e',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: '38px',
    width: '105px',
    fontWeight: 'bold',
    padding: '5px 20px',
    textAlign: 'center',
    marginBottom: '0px',
    '& + input[type=radio] + label': {
      borderLeft: 'solid 3px #675f6b'
    }
  },
  filterChecked: {},
  filterClass: {
    position: 'absolute',
    visibility: 'hidden',
    display: 'none',
    '&:checked + label': {
      color: '#ccc8ce',
      background: '#675f6b'
    }
  },
  productHidden: {
    display: 'none !important',
    opacity: 0
  },
  productContainer: {
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    // margin: '5px',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '170px',
    height: '170px',
    border: '3px solid #fff',
    boxShadow: '1px 1px 4px #ccc',
    overflow: 'hidden',
    '&:hover, &.active': {
      boxShadow: '3px 4px 4px #ccc',
      border: '3px solid red',
      '& > img': {
        transform: 'scale(1.3)'
      }
    },
    '&.active': {
      overflowY: 'scroll',
    //'& div[class^=productActionOptionsContainer]': {
    //'& div[class^=$productActionOptionsContainer]': {
      '& div$productActionOptionsContainer': {
        top: '5vw',
        left: '5vw',
        width: '90vw',
        height: '80vh',
        zIndex: '9999999',
        '&:after': {
          content: '"."',
          position: 'fixed',
          top: 0,
          right: 0,
          backgroundColor: 'rgba(0,0,0,.6)',
          width: '100%',
          height: '100%',
          zIndex: -1
        }
      },
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  },
  productContainerMenuActive: {},
  foldersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    minHeight: '50px',
    alignItems: 'center',
    paddingRight: '40px'
  },
  floatingButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    border: 'none',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)',
    overflow: 'hidden',
    width: '56px',
    height: '56px',
    lineHeight: '40px',
    padding: 0,
    backgroundColor: '#26a69a',
    borderRadius: '50%',
    cursor: 'pointer',
    verticalAlign: 'middle',
    '&:focus': {
      outline: 'none'
    }
  },
  headerContent: {
    height: '40px',
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0
  },
  title: {
    margin: '0',
    width: '100%',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '18px'
  },
  closeButton: {
    justifyContent: 'center',
    background: 'none',
    border: 'solid 1px #ccc',
    display: 'flex',
    position: 'absolute',
    right: '0',
    width: '36px',
    height: '36px',
    '&:hover path': {
      fill: '#e67364'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  modal: {
    display: 'none',
    left: 0,
    top: 0,
    zIndex: 9999,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.4)'
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  '@keyframes fill': {
    '0%': {
      width: '0%',
      height: '1px'
    },
    '50%': {
      width: '100%',
      height: '1px'
    },
    '100%': {
      width: '100%',
      height: '100%',
      background: '#eee'
    }
  },
  '@keyframes fadeIn': {
    from: { top: '-100px', opacity: 0 },
    to: { top: '0px', opacity: 1 }
  },
  '@keyframes slideIn': {
    '100%': { left: '0px' }
  },
  modalOpen: {
    display: 'table',
    position: 'fixed',
    '&.second-modal-open': {
      position: 'fixed',
      zIndex: 9999,
      overflow: 'hidden'
    }
  },
  modalDialog: {
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  modalContent: {
    display: 'block',
    // flexDirection: 'column',
    margin: 'auto',
    backgroundColor: '#fff',
    position: 'relative',
    padding: 0,
    overflowX: 'hidden',
    outline: 0,
    textAlign: 'justify',
    width: '100vw',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    animationName: 'fadeIn',
    animationDuration: '0s',
    height: '100vh',
    overflowY: 'scroll'
  },
  inputWrapper: {
    display: 'flex',
    width: '100%',
    margin: 'auto',
    marginTop: '20px',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  footerContent: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  materialButton: {
    border: 'none',
    textAlign: 'center',
    letterSpacing: '.5px',
    fontSize: '14px',
    fontWeight: 400,
    padding: '.5rem 2rem',
    textTransform: 'uppercase',
    '-webkit-tap-highlight-color': 'transparent',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)',
    '&:hover': {
      boxShadow: '0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2)'
    },
    '&:focus': {
      outline: 'none'
    },
    '&:disabled': {
      pointerEvents: 'none',
      backgroundColor: '#DFDFDF',
      boxShadow: 'none',
      color: '#9F9F9F',
      cursor: 'default',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: '#DFDFDF'
      }
    },
    color: 'white'
  },
  confirmButtonClass: {
    extend: 'materialButton',
    backgroundColor: '#26a69a',
    '&:hover': {
      backgroundColor: '#2bbbad'
    },
    marginRight: '30px'
  },
  '@media (max-width: 991px)': {
    tablesContainer: {
      flexWrap: 'nowrap',
      flexDirection: 'column'
    },
    sizesContainer: {
      width: '100%'
    },
    coloursContainer: {
      width: 'unset',
      margin: 'auto'
    },
    productContainer: {
      width: '120px',
      height: '120px',
      '&:hover, &.active': {
        '& > img': {
          transform: 'unset'
        }
      }
    },
    figureColourContainer: {
      '&.figureColourContainerActive': {
        '& > img': {
          transform: 'unset'
        }
      }
    },
    modalContent: {
      width: '90vw'
    },
    inputWrapper: {
      height: 'unset'
    },
    productName: {
      position: 'absolute',
      background: 'white',
      top: '3px',
      fontSize: '11px',
      padding: '3px',
      width: '90%',
      margin: 0
    },
    productImage: {
      position: 'absolute',
      bottom: 0
    },
    colourImage: {
      paddingTop: '8px',
      transformOrigin: 'top'
    },
    singleOptionColourContainer: {
      width: '100px',
      height: '100px',
      marginTop: '10px'
    },
    colourName: {
      fontSize: '12px',
      margin: '10px 5px 0 5px',
      maxHeight: '20px'
    },
    inputContainer: {
      width: '100%',
      justifyContent: 'center'
    },
    confirmButtonClass: {
      marginRight: '0px'
    },
    footerContent: {
      justifyContent: 'center'
    }
  },
  '@media (min-width : 320px) and (max-width : 480px)': {
    singleOptionColourContainer: {
      width: '70px',
      height: '70px'
    },
    colourImage: {
      transformOrigin: 'unset',
      transform: 'scale(.7)',
      paddingTop: '50px'
    },
    colourName: {
      fontSize: '11px',
      position: 'absolute',
      top: 0,
      width: '100%',
      margin: 0,
      background: '#fff'
    }
  }
};

// Compile styles, apply plugins.
const sheet = jss.createStyleSheet(style);

const { classes } = sheet.attach();
// If you want to render on the client, insert it into DOM.

export default classes;
