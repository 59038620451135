export const setClassesToNotActive = (Api, reset = false) => {
  if (reset) {
    Api.resetActiveElement();
    return;
  }
  const previousActive = Api.getActiveElement();
  if (previousActive.length) {
    previousActive[0].className = previousActive[1];
  }
};
