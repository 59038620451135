const promiseFromScript = ({ pkg, src, name }) =>
  new Promise(res => {
    if (pkg in window) {
      res();
    } else {
      window[name] = () => res();
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = window[name];
      const ref = document.getElementsByTagName('script')[0];
      ref.parentNode.insertBefore(script, ref);
      script.src = src;
    }
  });

export default promiseFromScript;
