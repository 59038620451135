import classes from '../style';
import { setClassesToNotActive } from './';

const lookForColours = () => {
  return document.querySelectorAll(
    `.${classes.productContainer}.active .${classes.productActionOptionsContainer} .${
      classes.singleOptionColourContainer
    } .${classes.figureColourContainer}`
  );
};

const isElementTargeted = (targetClasses, classRequired) => targetClasses.indexOf(classes[classRequired]) > -1;
const parent = (event, isParent) => (isParent ? event.target.parentNode.className : event.target.className);

const getCode = el => el.attributes.getNamedItem('data-id').value;

const lookForSizes = () => {
  const [firstSize, ...otherSizes] = document.querySelectorAll(
    `.${classes.productContainer}.active .${classes.productActionOptionsContainer} .${classes.sizesContainer} .${
      classes.singleOptionSizeContainer
    }`
  );
  return [firstSize, ...otherSizes];
};

const closeModals = () => {
  $('html').removeClass(classes.htmlNoOverflow);
  const [modal] = document.getElementsByClassName(classes.modalOpen);
  modal.className = `${classes.modal}`;
  const [secondModal] = document.querySelectorAll(`.${classes.productContainer}.active`);
  const secondModalNotActiveAnymore = secondModal.className.replace(' active', '');
  secondModal.className = secondModalNotActiveAnymore;
};
const activeTargeted = (el, ProductsApi) => {
  const [otherProductsActive] = document.getElementsByClassName('figureColourContainerActive');
  if (otherProductsActive) otherProductsActive.className = classes.figureColourContainer;
  el.className += ' figureColourContainerActive';
  const colours = lookForColours();
  ProductsApi.setColour(getCode(el));
  if (!lookForSizes()[0] && getCode(el) && colours.length > 1) {
    ProductsApi.sendUserChoices(ProductsApi.colourSelected, null, closeModals);
  }
};
export const mainClickEvent = (wrapper, ProductsApi) =>
  wrapper.addEventListener('click', ev => {
    const isProductContainer = isElementTargeted(parent(ev, false), 'productContainer')
      ? ev.target
      : isElementTargeted(parent(ev, true), 'productContainer') ? ev.target.parentNode : false;
    if (isProductContainer && isProductContainer.className.indexOf('active') === -1) {
      const [modal] = document.getElementsByClassName(classes.modalOpen);
      modal.className += ' second-modal-open';
      setClassesToNotActive(ProductsApi);
      ProductsApi.setActiveElement(isProductContainer, isProductContainer.className);
      isProductContainer.className += ' active';
      const [firstColourToClick, ...otherColours] = lookForColours();
      if (firstColourToClick) {
        if (otherColours && lookForSizes()[0]) {
          ProductsApi.setColour(firstColourToClick.getAttribute('data-code'));
          firstColourToClick.click();
        }
        // console.log(lookForSizes(), 'lookForSizes()');
        if (!otherColours.length && !lookForSizes()[0]) {
          ProductsApi.sendUserChoices(ProductsApi.colourSelected, null, closeModals);
        }
      } else {
        ProductsApi.setColour(null);
        if (!lookForSizes()[0]) ProductsApi.sendUserChoices(ProductsApi.colourSelected, null, closeModals);
      }
    }
    if (isElementTargeted(parent(ev, false), 'productActionOptionsContainer')) {
      setClassesToNotActive(ProductsApi);
      const [modal] = document.getElementsByClassName(classes.modalOpen);
      modal.className = `${classes.modal} ${classes.modalOpen}`;
    }

    isElementTargeted(parent(ev, false), 'figureColourContainer')
      ? activeTargeted(ev.target, ProductsApi)
      : isElementTargeted(parent(ev, true), 'figureColourContainer')
        ? activeTargeted(ev.target.parentNode, ProductsApi)
        : void 0;

    if (isElementTargeted(parent(ev, false), 'sizeOption')) {
      ProductsApi.sendUserChoices(ProductsApi.colourSelected, getCode(ev.target), closeModals);
      // console.log(getCode(ev.target.parentNode) + 'EEEE SIEZE');
    } else if (isElementTargeted(parent(ev, true), 'sizeOption')) {
      ProductsApi.sendUserChoices(ProductsApi.colourSelected, getCode(ev.target.parentNode), closeModals);
    }

    // console.log(ProductsApi);
  });
