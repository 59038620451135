import classes from './style';
import CONFIG from './globals/config.json';
import { setClassesToNotActive, productHTML, baseHTML } from './presets';
let _;
const productsTemplate = require('pug-loader!./templates/products.pug');

const getLookupClass = id => 'epospl-' + id;
export const createEmptyDiv = (html = false) => {
  const section = document.createElement('section');
  section.className = 'wrapper-section';
  if (html) section.innerHTML = html;
  return section;
};

const _getElementFromId = id => {
  const lookupClass = getLookupClass(id);
  const [elementToShow] = document.getElementsByClassName(lookupClass);
  // console.log([elementToShow]);
  return [elementToShow, lookupClass];
};

let getElementFromId;

export let Action;

const generateInitialHTML = (everyProduct, isFirstTime, ProductsApi, cb) => {
  _ = window._;

  getElementFromId = _.memoize(_getElementFromId);

  Action = (type, array, Api) => {
    _.each(array, id => {
      const [elementfromHTML, lookupClass] = getElementFromId(id.hasOwnProperty('product') ? id.product : id);
      switch (type) {
        case 'hide':
          elementfromHTML.className = `${classes.productContainer} ${lookupClass} ${classes.productHidden}`;
          break;
        case 'show':
          elementfromHTML.className = `${lookupClass} ${classes.productContainer}`;
          break;
        default:
          break;
      }
    });
  };
  const BaseContainer = createEmptyDiv();
  const favourites = [];
  const toBeConverted = [];
  _.each(everyProduct, prod => {
    const { product, folder, converted } = prod;
    if (product == 'config' && !ProductsApi.config.product) ProductsApi.setConfig(prod);
    //console.log(prod, 'prod');
    const prodFolder = folder ? ProductsApi.addFolder(folder) : '99999';
    if (prodFolder.indexOf(ProductsApi.favouriteFolder) > -1) {
      favourites.push(ProductsApi.setFavourites(prod));
      ProductsApi.addProductInUI(product);
    }
    !isFirstTime && ProductsApi.addKeyToArrayOfAllProducts(product);
    !converted && toBeConverted.push(prod);
  });
  !isFirstTime &&
    toBeConverted.length &&
    setTimeout(() => ProductsApi.saveBase64(toBeConverted, true), CONFIG.SAVE_BASE_64_TIMEOUT);

  BaseContainer.className = classes.modal;
  // console.log(ProductsApi, 'ProductsApi');

  BaseContainer.innerHTML = require('pug-loader!./templates/wrapper.pug')({
    ...baseHTML,
    filters: ProductsApi.foldersToRender,
    favouriteFolder: ProductsApi.favouriteFolder
  });
  //console.log(ProductsApi, 'favourites');
  const [baseContainer] = BaseContainer.getElementsByClassName(classes.inputWrapper);
  if (favourites.length) {
    baseContainer.innerHTML += productsTemplate({
      ...productHTML,
      products: favourites
    });
  }
  return [BaseContainer, cb];
};

const generateTemplate = (Api, allProd) =>
  allProd.length
    ? new Promise(res => res([generateInitialHTML(allProd, true, Api)[0], () => { }]))
    : Api.read('getAll').then(allProducts => generateInitialHTML(allProducts, false, Api, Api.startRenderingOther));

const ToRender = (A, B) => _.difference(A, B);
const ToShow = (A, B) => _.filter(A, k => B.indexOf(k) == -1);
const ToHide = (A, B) => _.without(A, ...B);
const Update = (ProductsApi, toRender, toHide, toShow) => {
  ProductsApi.addProductInUI(toRender);

  toHide.length && Action('hide', toHide, ProductsApi);

  if (toRender.length) {
    ProductsApi.read('getSome', toRender).then(productsToRender => {
      const baseContainer = document.getElementsByClassName(classes.inputWrapper);

      const productsContainerFromBase = baseContainer.item(0);
      const wrapperItem = createEmptyDiv(
        productsTemplate({
          ...productHTML,
          products: productsToRender
        })
      );
      // console.log(productsToRender, 'productsToRender');

      const collection = wrapperItem.getElementsByClassName(classes.productContainer);
      const collectionLength = collection.length;
      for (let i = collectionLength - 1; i >= 0; i--) {
        if (collection.item(i).nodeType == Node.ELEMENT_NODE) {
          productsContainerFromBase.appendChild(collection.item(i));
        }
      }
    });
  }
  toShow.length && Action('show', toShow, ProductsApi);
};
export const updateTemplate = (ProductsApi, action) => {
  setClassesToNotActive(ProductsApi, true);
  // console.log(action, 'ACTION');

  if (action.type == 'filter' && action.filteredValue) {
    if (typeof ProductsApi.config.productPerFolder[action.filteredValue] === 'undefined') {
      Action('hide', ProductsApi.productInTheUI, ProductsApi);
      return;
    }
    const toRender = ToRender(ProductsApi.config.productPerFolder[action.filteredValue], ProductsApi.productInTheUI);
    Update(
      ProductsApi,
      toRender,
      ToHide(ProductsApi.productInTheUI, ProductsApi.config.productPerFolder[action.filteredValue]),
      ToShow(ProductsApi.config.productPerFolder[action.filteredValue], toRender)
    );
  } else if (action.type === 'search' && action.arrayOfSearchedItems) {
    const toRender = ToRender(action.arrayOfSearchedItems, ProductsApi.productInTheUI);

    Update(
      ProductsApi,
      toRender,
      ToHide(ProductsApi.productInTheUI, action.arrayOfSearchedItems),
      ToShow(action.arrayOfSearchedItems, toRender)
    );
  }
};

export default generateTemplate;
