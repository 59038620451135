import classes from './style.js';
let MODAL_CLOSED_CLASSNAMES;
let MODAL_REFERENCE;
let CALLBACK_REFERENCE;

const searchLookup = (Api, val) => {
  Api.searchValues(val);
};

const openLookup = (modal, cb, Api) => {
  window.$('body').trigger('epospl-open-before');
  $('html').addClass(classes.htmlNoOverflow);
  MODAL_CLOSED_CLASSNAMES = modal.className;
  MODAL_REFERENCE = modal;
  CALLBACK_REFERENCE = cb;
  Api.stopRenderingOther();
  modal.className += ` ${classes.modalOpen}`;

  window.$('body').trigger('epospl-open');
};

const closeLookup = cb => {
  window.$('body').trigger('epospl-close-before');
  $('html').removeClass(classes.htmlNoOverflow);
  MODAL_REFERENCE.className = MODAL_CLOSED_CLASSNAMES;
  if (typeof cb === 'function') cb();
  Array.prototype.forEach.call(MODAL_REFERENCE.getElementsByClassName(classes.inputClass), i => (i.value = ''));

  // arr ? CALLBACK_REFERENCE(arr) : CALLBACK_REFERENCE();

  window.$('body').trigger('epospl-close');
};

export { openLookup, closeLookup, searchLookup };
