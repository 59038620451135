import { Api } from './presets';
import initDOM from './initDOM';
import loadLodash from './scriptQueue/lodash';
import idb from 'idb';
import CONF from './globals/config.json';

window.eposplInit = (window, document) => {
  Promise.all([loadLodash(document, window)]).then(r => {
    const Products = new Api('product-list');
    const Init = () => Products.init();

    const Then = r => {
      return initDOM(window, document, Products, r);
    };
    const Err = e => {
      //console.log(e, 'THIS IS E');
      if (e == 'BAD_RESPONSE') {
        idb.delete(CONF.DBNAME).then(() => {
          Init().then(Then, Err);
          // console.log('done!');
        });
      } else if (e == 'TWO_TRY') {
        idb.delete(CONF.DBNAME).then(() => {
          if (window.$) {
            window.$('body').trigger('epospl-error');
          }
          return;
        });
      }
    };
    Init().then(Then, Err);
  });
};

window.eposplInit(window, document);
