import idb from 'idb';
import axios from 'axios';
import CONF from '../globals/config.json';
import classes from '../style';
import { mainClickEvent, productHTML } from './';
import { createEmptyDiv, updateTemplate } from '../template';
import { createFloatingButton } from './createFloatingButton';

const productsTemplate = require('pug-loader!../templates/products.pug');
const isLocalHost = w => ((w.location.origin.indexOf('ngrok') > -1) || (w.location.origin.indexOf('127.0.0.1') > -1) || (w.location.origin.indexOf('localhost') > -1));
const COMPLETE_URL = CONF.PATH_PREFIX + CONF.LIST_URL;
const axiosOPT = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
};
let _;
let spin = () => createFloatingButton(document, document.getElementById(CONF.PAGE_DIV_ID), false, () => {}, this);
const omitAndJoin = toOmit => c => Object.values(_.omit(c, toOmit)).join(' ');
const mapOmitAndJoin = (obj, toOmit) => obj.map(omitAndJoin(toOmit)).join(' ');

const getDataIndexedToSave = data =>
  data.reduce((acc, d) => {
    if (d.product == 'config') return acc;
    acc[d.product] = {
      id: d.product,
      index: [
        d.product,
        d.name,
        mapOmitAndJoin(d.sizes, ['fixprice', 'image', 'tags', 'code']),
        mapOmitAndJoin(d.colours, ['image', 'converted', 'tags'])
        // d.sizes.map(omitAndJoin(['fixprice', 'image', 'tags', 'code'])).join(' '),
        // d.colours.map(omitAndJoin(['image', 'converted', 'tags'])).join('')
      ]
        .join(' ')
        .toLowerCase()
    };
    return acc;
  }, {});

const saveDataIndexedInCache = data => {
  if (window.caches) {
    caches.open(CONF.DBNAME).then(cache => {
      cache.put(
        `/${CONF.DBNAME}-indexedData`,
        new Response(new Blob([JSON.stringify(data)], { type: 'application/json' }), { status: 200 })
      );
    });
  }
};
class Api {
  constructor(objectStore) {
    _ = window._;
    this.indexedInfos = [];
    this.colourSelected = [];
    this.base64Logo = CONF.BASE_64_LOGO;
    this.config = {};
    this.allowRenderingOther = true;
    this.renderingTimeout = undefined;
    this.edition = window.EDITION ? String(window.EDITION) : 53;
    this.favouriteFolder = '0';
    this.folders = [];
    this.hasTobeInitialized = false;
    this.favouriteProducts = [];
    this.allProducts = [];
    this.foldersToRender = [];
    this.productsContainerDOMReference = undefined;
    this.productInTheUI = [];
    this.coloursImagesToPreload = [];
    this.store = objectStore;
    this.activeElement = [];
    this.hasTobeInitialized = false;
    this.currentFolder = null;
    this.DBURL = isLocalHost(window) ? COMPLETE_URL : CONF.LIST_URL;
  }
  dbPromise = (init = false) => {
    let promise = idb.open(CONF.DBNAME, this.edition, db => {
      const dbExists = db.objectStoreNames.contains(this.store);
      if (String(db.oldVersion) !== String(this.edition) || !dbExists) {
        this.hasTobeInitialized = true;
        if (!dbExists) {
          db.createObjectStore(this.store, { keyPath: 'product' });
          return this.fillDatabase(init);
        }
        this.clear().then(() => {
          return this.fillDatabase(init);
        });
      }
    });
    if (!init) return promise;
    promise
      .then(db => {
        if (!this.hasTobeInitialized) {
          // this.keys().then(k => {
          this.read('get', 'config').then(config => {
            if (!config) {
              this.clear().then(() => {
                return this.fillDatabase(init);
              });
            } else {
              this.setConfig(config);
              this.favouriteFolder = String(config.favourite);
              this.currentFolder = this.favouriteFolder;
              this.foldersToRender = config.folders;
              this.folders.push(this.favouriteFolder);
              init.res([]);
            }
          });
          // });
        }
      })
      .catch(err => {
        console.error(err, 'ERROR');
        idb.delete(CONF.DBNAME).then(() => {
          window.eposplInit(window, document);
          // console.log('done!');
        });
      });
  };
  search = k => _.pluck(_.filter(this.indexedInfos, t => t.index.indexOf(k) > -1), 'id');
  Update = k => updateTemplate(this, { type: 'search', arrayOfSearchedItems: this.search(k) });
  searchValues = keyword => {
    // console.log(this.config, 'config');
    if (!keyword.length) {
      updateTemplate(this, { type: 'filter', filteredValue: this.currentFolder });
      return;
    }
    const k = keyword.toLowerCase();
    if (Object.keys(this.indexedInfos).length > 0) {
      this.Update(k);
      return;
    }
    this.getIndexedInfos().then(infos => {
      this.setIndexedInfos(infos);
      this.Update(k);
    });
  };
  sendUserChoices = (colour, size, cb) => {
    // console.log(colour, size)
    const product = this.activeElement[0].getAttribute('data-id');
    // console.log([{ product, size, colour }], '[{ product, size, colour }]');
    if (window.$) {
      window.$('body').trigger('epospl-buy', [{ product, size, colour }]);
    }
    cb();
  };
  setIndexedInfos = infos => {
    if (infos) {
      this.indexedInfos = infos;
      return infos;
    }
  };
  getIndexedInfos = () =>
    caches.match(`/${CONF.DBNAME}-indexedData`).then(
      res =>
        res
          ? res.json()
          : this.read('getAll').then(dataToSave => {
            const dataIndexedToSave = getDataIndexedToSave(dataToSave);
              // console.log(dataIndexedToSave, 'dataIndexedToSave');
            saveDataIndexedInCache(dataIndexedToSave);
            return this.setIndexedInfos(dataIndexedToSave);
          })
    );
  setCurrentFolder = f => (this.currentFolder = f);
  setColour = code => (this.colourSelected = code);
  setDOMContainerReference = baseContainer => {
    this.productsContainerDOMReference = baseContainer;
    mainClickEvent(baseContainer, this);
  };
  startRenderingOther = () => {
    const allProductsArrayLength = this.allProducts.length;
    // console.log(this.allProducts, 'this.allProducts');

    if (
      allProductsArrayLength &&
      typeof this.productsContainerDOMReference !== 'undefined' &&
      this.allowRenderingOther
    ) {
      const key = this.allProducts[allProductsArrayLength - 1];
      if (_.indexOf(this.productInTheUI, key) > -1) {
        this.allProducts.pop();
        if (this.renderingTimeout) {
          this.renderingTimeout = undefined;
        }
        this.startRenderingOther();
      } else {
        // console.log(key, 'KEY FROM START RENDERING');
        this.read('get', key).then(product => {
          // console.log('SECOND STEP' + key);
          // console.log(!!product, 'product from rendering oyher');
          const plainHtml = productsTemplate({ ...productHTML, hidden: true, products: [product] });
          const wrapperItem = createEmptyDiv(plainHtml);
          const collection = wrapperItem.getElementsByClassName(classes.productContainer);
          // const collectionLength = collection.length;
          this.productsContainerDOMReference.appendChild(collection.item(0));
          this.addProductInUI(key);
          // Action('addEventListener', [product], this);
          this.allProducts.pop();
          this.renderingTimeout = setTimeout(() => {
            this.renderingTimeout = undefined;
            this.startRenderingOther();
          }, CONF.OTHER_PRODUCTS_TIMEOUT);
        });
      }
    }
  };
  allowAndStartRenderingOther = () => {
    this.allowRenderingOther = true;
    this.startRenderingOther();
  };
  stopRenderingOther = () => {
    this.allowRenderingOther = false;
    clearTimeout(this.renderingTimeout);
  };
  addKeyToArrayOfAllProducts = key => {
    // console.log(key, 'key');
    // console.log(this.allProducts.indexOf(key), 'this.allProducts.indexOf(key)');
    if (key != 'config') this.allProducts.indexOf(key) < 0 && this.allProducts.push(key);
  };
  setActiveElement = (el, classes) => (this.activeElement = [el, classes]);
  getActiveElement = () => this.activeElement;
  resetActiveElement = () => (this.activeElement = []);
  setFavourites(prod) {
    this.favouriteProducts.push(prod);
    return prod;
  }
  addProductInUI = productID => {
    Array.isArray(productID)
      ? _.each(productID, p => this.productInTheUI.push(p))
      : this.productInTheUI.push(productID);
  };
  setConfig = config => {
    this.config = config;
    return config;
  };
  optimizeImgURL = (imgUrl, w = 100) => imgUrl.replace('upload/', `upload/f_auto,q_auto,w_${w},dpr_1.0/`);
  addFolder(folders) {
    // console.log(folders, 'folders')
    if (folders == '') return ['0'];
    if (Array.isArray(folders)) {
      return folders.map(f => {
        const Folder = String(f);
        !this.folders.filter(f => f == Folder).length && this.folders.push(Folder);
        return Folder;
      });
    } else if (Number.isInteger(folders)) {
      const Folder = String(folders);
      this.folders.indexOf(Folder) < 0 && this.folders.push(Folder);
      return [Folder];
    } else if (folders.split(',').length) {
      return folders.split(',').map(f => {
        const Folder = String(f);
        this.folders.indexOf(f) < 0 && this.folders.push(Folder);
        return Folder;
      });
    }
    throw new Error('wrong folders format');
  }
  fillDatabase(init) {
    // console.log('filling');
    return axios.get(this.DBURL, axiosOPT).then(r => {
      if (r.status !== 200) {
        throw new Error('Problem with getting data from the server');
      }
      // console.log(r, 'DATA FROM SERVER');
      if (!r.data.products && !this.tryToFillDB && init) {
        this.tryToFillDB = true;
        init.rej('BAD_RESPONSE');
        return;
      } else if (!r.data.products && this.tryToFillDB) {
        init.rej('TWO_TRY');
        return;
      }

      this.foldersToRender = r.data.eposfolders;
      const data = r.data.products;
      if (data.length) {
        let productPerFolder = {};
        this.favouriteFolder = String(r.data.eposfolders[0].eposfolder);
        this.folders.push(String(this.favouriteFolder));
        const dataToSave = data.map(d => {
          const whichFolders = d.eposfolders;
          this.addKeyToArrayOfAllProducts(d.product);
          _.each(whichFolders, folder => {
            if (productPerFolder[folder]) {
              productPerFolder[folder].push(d.product);
            } else {
              productPerFolder[folder] = [d.product];
            }
          });
          const imageAvailable = d.image.trim().length > 1;

          return {
            image: imageAvailable ? this.optimizeImgURL(d.image, 100) : this.base64Logo,
            folder: d.eposfolders,
            product: d.product,
            name: d.name,
            colours: d.colours.map(c => ({
              ...c,
              image: c.image.trim().length > 1 ? this.optimizeImgURL(c.image, 80) : this.base64Logo,
              converted: !(c.image.trim().length > 1)
            })),
            sizes: d.sizes,
            converted: !imageAvailable
          };
        });

        const dataIndexedToSave = getDataIndexedToSave(dataToSave);
        // console.log(dataIndexedToSave, 'dataIndexedToSave');
        this.setIndexedInfos(dataIndexedToSave);
        saveDataIndexedInCache(dataIndexedToSave);

        const config = {
          product: 'config',
          favourite: this.favouriteFolder,
          image: '',
          productPerFolder,
          folders: this.foldersToRender,
          numberOfItems: dataToSave.length + 1
        };
        dataToSave.push(config);
        // console.log(dataToSave, 'dataToSave');
        this.setConfig(config);
        if (init) init.res(dataToSave);
        return this.write(dataToSave).then(() => {
          this.startRenderingOther();
          return this.saveBase64(dataToSave);
        });
      }
      throw new Error('data from server is empty');
    });
  }
  getBase64FromBuffer = data => new Buffer(data, 'binary').toString('base64');
  getImg = (imgURL, arr, img, fromTemplate) =>
    axios.get(imgURL, { responseType: 'arraybuffer' }).then(r => {
      if (r.status == 200) {
        this.write({ ...img, image: this.getBase64FromBuffer(r.data), converted: true }).then(() => {
          // console.log('save complete', r);
          if (arr.length) this.saveBase64(arr, fromTemplate);
        });
      }
    });

  saveBase64 = (arr, fromTemplate = false) => {
    const img = arr[arr.length - 1];
    const url = img.image;
    this.coloursImagesToPreload.push({ id: img.product, arr: img.colours });
    arr.pop();
    if (url.length && img && !img.converted) {
      if (!fromTemplate) {
        this.getImg(url, arr, img, fromTemplate);
      } else {
        // console.log(img.product, 'img.product');
        this.read('get', img.product).then(prod => {
          // console.log('yet to be getted', prod);
          if (prod && !prod.converted) {
            this.getImg(url, arr, img, fromTemplate);
          }
        });
      }
    } else if (arr.length) this.saveBase64(arr, fromTemplate);
  };

  getInstance(init) {
    return this.dbPromise(init);
  }
  init = () => {
    if (typeof spin === 'function') {
      spin();
      spin = false;
    }
    return new Promise((res, rej) => this.getInstance({ res, rej }));
  };
  read(verb, data) {
    return this.getInstance().then(db => {
      const tx = db.transaction(this.store, 'readonly');
      const store = tx.objectStore(this.store);
      return this[verb](store, data);
    });
  }
  write(data) {
    return this.getInstance().then(db => {
      const tx = db.transaction(this.store, 'readwrite');
      const store = tx.objectStore(this.store);
      if (Array.isArray(data)) {
        _.each(data, product => {
          this.put(store, product);
        });
      } else {
        this.put(store, data);
      }
      return tx.complete;
    });
  }
  delete(key) {
    return this.getInstance().then(db => {
      const tx = db.transaction(this.store, 'readwrite');
      tx.objectStore(this.store).delete(key);
      return tx.complete;
    });
  }
  clear() {
    return this.getInstance().then(db => {
      const tx = db.transaction(this.store, 'readwrite');
      tx.objectStore(this.store).clear();
      return tx.complete;
    });
  }
  put(store, data) {
    return store.put(data);
  }
  getAll(store) {
    return store.getAll();
  }
  getSome(store, dataArray) {
    return Promise.all(dataArray.map(key => this.get(store, key)));
  }
  get(store, key) {
    return key.length || !Number.isNaN(key) ? store.get(key) : new Error('no key provided');
  }
  keys() {
    return this.getInstance().then(db => {
      const tx = db.transaction(this.store);
      const keys = [];
      const store = tx.objectStore(this.store);

      // This would be store.getAllKeys(), but it isn't supported by Edge or Safari.
      // openKeyCursor isn't supported by Safari, so we fall back
      (store.iterateKeyCursor || store.iterateCursor).call(store, cursor => {
        if (!cursor) return;
        keys.push(cursor.key);
        cursor.continue();
      });

      return tx.complete.then(() => keys);
    });
  }
}

export { Api };
