import classes from '../style';

export const baseHTML = {
  closeButtonClass: classes.closeButton,
  modalDialog: classes.modalDialog,
  modalContent: classes.modalContent,
  headerContent: classes.headerContent,
  title: classes.title,
  inputWrapper: classes.inputWrapper,
  footerContent: classes.footerContent,
  confirmButtonClass: classes.confirmButtonClass,
  foldersContainer: classes.foldersContainer,
  filterClass: classes.filterClass,
  filterChecked: classes.filterChecked,
  filterLabel: classes.filterLabel,
  radioGroup: classes.radioGroup,
  searchWrapper: classes.searchWrapper,
  searchInput: classes.searchInput,
  searchContainer: classes.searchContainer,
  searchIcon: classes.searchIcon,
  floatingButton: classes.floatingButton,
  searchClosed: classes.searchClosed
};

export const productHTML = {
  productContainer: classes.productContainer,
  productName: classes.productName,
  productImage: classes.productImage,
  productHidden: classes.productHidden,
  productActionOptionsContainer: classes.productActionOptionsContainer,
  singleOptionSizeContainer: classes.singleOptionSizeContainer,
  optionClass: classes.optionClass,
  hidden: false,
  sizeOption: classes.sizeOption,
  sizesContainer: classes.sizesContainer,
  coloursContainer: classes.coloursContainer,
  tablesContainer: classes.tablesContainer,
  colourName: classes.colourName,
  colourImage: classes.colourImage,
  figureColourContainer: classes.figureColourContainer,
  colourLinkContainer: classes.colourLinkContainer,
  singleOptionColourContainer: classes.singleOptionColourContainer,
  sizeName: classes.sizeName,
  sizePrice: classes.sizePrice
};
