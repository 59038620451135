import classes from './style';
import { closeLookup } from './openCloseSearch';
import { updateTemplate } from './template';

const closeModalByClickingOutside = (modal, Api) => {
  modal.addEventListener('click', ev => {
    var path = ev.path;
    if (path) {
      ev.path[0].className === classes.modalDialog && closeLookup(Api.allowAndStartRenderingOther);
    } else {
      ev.target.className === classes.modalDialog && closeLookup(Api.allowAndStartRenderingOther);
    }
  });
};

const triggerFilter = (modal, Api) => {
  const [filters] = modal.getElementsByClassName(classes.foldersContainer);
  filters.addEventListener('click', ev => {
    if (ev.target.nodeName == 'INPUT') {
      const filteredValue = ev.target.getAttribute('value');
      Api.setCurrentFolder(filteredValue);
      updateTemplate(Api, { type: 'filter', filteredValue });
    }
    const [searchBar] = modal.getElementsByClassName(classes.searchInput);
    //console.log(searchBar, 'BAr');
    if (searchBar) {
      searchBar.value = '';
    }
  });
};

const showSearchBar = (d, modal, Api) => {
  const [searchBar] = d.getElementsByClassName(classes.searchWrapper);
  searchBar.addEventListener('click', ev => {
    if (searchBar.getAttribute('class').indexOf(classes.searchClosed) > -1) {
      searchBar.className = classes.searchWrapper;
      searchBar.children[0].children[1].focus();
    }
  });
};

const createRulesInModal = (d, modal, Api) => {
  closeModalByClickingOutside(modal, Api);
  triggerFilter(modal, Api);
  showSearchBar(d, modal, Api);
};

export default createRulesInModal;
