import generateTemplate from './template.js';
import classes from './style';
import { closeLookup, searchLookup } from './openCloseSearch';
import createRulesInModal from './createRulesInModal';
import { createFloatingButton } from './presets';
import CONF from './globals/config.json';
let _;

export default (w, d, Api, allProducts = false) =>
  generateTemplate(Api, allProducts).then(([generatedTemplate, cb = () => { }]) => {
    //console.log(generatedTemplate, 'generated')
    if (window.$ && window.$(`.${classes.modal}`).length) {
      window.$(`.${classes.modal}`).remove();
    }
    _ = window._;
    const [body] = d.getElementsByTagName('BODY');
    const f = d.createDocumentFragment();
    f.appendChild(generatedTemplate);
    body.appendChild(f);
    const buttonContainer = d.getElementById(CONF.PAGE_DIV_ID);
    const [closeBtn] = d.getElementsByClassName(classes.closeButton);
    const [baseContainer] = d.getElementsByClassName(classes.inputWrapper);
    const [searchInput] = d.getElementsByClassName(classes.searchInput);
    Api.setDOMContainerReference(baseContainer);
    closeBtn.addEventListener('click', ev => closeLookup(Api.allowAndStartRenderingOther));
    searchInput.addEventListener('keyup', _.debounce(ev => searchLookup(Api, ev.target.value), CONF.DEBOUNCE_TIME));
    createFloatingButton(d, buttonContainer, generatedTemplate, () => { }, Api);
    createRulesInModal(d, generatedTemplate, Api);
    cb();
  });
